<template>
  <v-container>
    <BoxTitle :title="'Seleciona tu oficina'">
      <template v-slot:content>
        <p>En MICROWD contarás con un equipo que te apoyará en todo momento: personal en oficina, asesor de crédito, mentora digital.</p>
        <p>
          Para poder asignarte al equipo correcto, <b>necesitaremos conocer cuál es la oficina más cercana a tu casa y negocio</b>. ¡Revisa
          el listado y selecciona en el desplegable la que mejor te convenga!
        </p>

        <v-form ref="formSelectOffice" v-model="formSelectOfficeValid" lazy-validation @submit.prevent="">
          <v-card-text class="pa-5">
            <v-select
              v-model="officeId"
              :items="$store.state.common.offices"
              :item-text="(item) => `${item.name} (${item.address.name} ${item.zone})`"
              item-value="id"
              label="Oficina"
              :rules="formRules.requiredField"
            />
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn class="mt-5 mt-md-0 primary normal-button" rounded @click="createRequest()">
              <span class="font-weight-bold">Continuar</span>
            </v-btn>
          </v-card-actions>
        </v-form>
      </template>
    </BoxTitle>
  </v-container>
</template>

<script>
import FormRulesMixin from "@/mixins/FormRulesMixin";
import BoxTitle from "@/components/common/BoxTitle.vue";

export default {
  name: "SelectOffice",
  components: { BoxTitle },
  mixins: [FormRulesMixin],
  data() {
    return {
      formSelectOfficeValid: false,
      officeId: ""
    };
  },
  async created() {
    if (!this.$store.state.borrower.borrower_data.identity_document) this.$router.push("/");
    else await this.$store.dispatch("common/getOfficesByCountry");
  },
  methods: {
    async createRequest() {
      if (this.$refs.formSelectOffice.validate()) {
        await this.$store.dispatch("request/createRequest", this.officeId);
        this.$router.push("/new-credit");
      }
    }
  }
};
</script>
